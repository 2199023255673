.container {
  display: flex;
  --stickerWidth: 300px;
  --stickerHeight: calc(var(--stickerWidth) * 1.4387096774);
}
.container video,
.container .camWrapper,
.container .camWrapper * {
  pointer-events: none;
}
@media all and (max-width: 800px) {
  .container {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.stickerForm {
  padding: 2rem;
  flex: 1;
}
.stickerForm h3 {
  font-size: 2rem;
  font-weight: 700;
}
.stickerForm p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}
.stickerForm form input {
  display: block;
}
@media all and (max-width: 800px) {
  .stickerForm {
    padding: 1rem;
  }
}

.videoContainer {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}

.camWrapper {
  box-shadow: 0px 5px 50px #0000004f;
  margin: 2rem 1rem 2rem 0;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
@media all and (max-width: 800px) {
  .camWrapper {
    margin: 0 calc((100vw - var(--stickerWidth)) / 2);
  }
  .camWrapper + button {
    display: block;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
    padding: 1rem;
    float: right;
    margin-top: 1rem;
    position: relative;
    z-index: 1;
  }
}

.overlay {
  position: absolute;
  top: 0;
}

.snapshotWrapper {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 1;
  background: rgba(255, 255, 255, 0.98);
  padding: 1rem;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
@media all and (max-width: 800px) {
  .snapshotWrapper {
    background: rgba(255, 255, 255, 0.98);
    display: block;
  }
}

.snapshotContent {
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.snapshotContent input {
  margin: 1rem 0;
}
.snapshotContent img {
  width: 500px;
  max-width: 100%;
  box-shadow: 0rem 0rem 2rem rgba(0, 0, 0, 0.2);
  margin: 2rem;
}
@media all and (max-width: 800px) {
  .snapshotContent {
    max-height: unset;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .snapshotContent img {
    max-height: 70vh;
    margin: 4rem 0.5rem 1rem;
  }
}

.imageWrappper {
  position: relative;
}
.imageWrappper > div {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}
@media all and (max-width: 800px) {
  .imageWrappper {
    max-width: 100vw;
  }
  .imageWrappper > div {
    max-width: 100%;
    top: 4rem;
  }
}
.button {
  font-size: 16px;
  font-family: "Rubik";
  cursor: pointer;
  margin-right: 2rem;
  padding: 0.5rem 1.5rem;
  border-style: solid;
  border-width: 1px;
  border-color: #161f4e;
  border-radius: 4px;
  background-color: transparent;
  transition: border 200ms ease;
  text-align: center;
}
@media all and (max-width: 800px) {
  .button {
    margin: 0 1rem;
  }
}
.button:hover {
  border-color: #dc3553;
  color: #dc3553;
}
.button__active, .button__active:hover {
  border-color: transparent;
  background-color: #161f4e;
  color: #fff;
}

.wrapper {
  margin: 40px 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
}
@media all and (max-width: 800px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
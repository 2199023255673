@font-face {
  font-family: 'Rubik';
  src: url('Rubik-Regular.woff2') format('woff2'),
      url('Rubik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-SemiBold.woff2') format('woff2'),
      url('Rubik-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-Bold.woff2') format('woff2'),
      url('Rubik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Rubik", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#sticker-cam-root {
  overflow: scroll;
  height: calc(100% - 40px);
}

main {
  width: 100%;
}
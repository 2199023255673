.appWrapper_7c07fb {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 3rem 3rem;
}
@media all and (max-width: 800px) {
  .appWrapper_7c07fb {
    margin: 0 0 2rem;
    max-width: 100vw;
    overflow: hidden;
  }
}
.wrapper_7c56c3 {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 2;
}
@media all and (max-width: 800px) {
  .wrapper_7c56c3 {
    margin-left: 1rem;
  }
}
.item_7c56c3 {
  background-color: #f0bd26;
  color: white;
  font-weight: 400;
  padding: 1rem;
  margin: 2rem 0 0;
  border: 2px solid #d4a20f;
  border-radius: 4px;
}
@media all and (max-width: 800px) {
  .item_7c56c3 {
    margin: 2rem 1rem 0;
  }
}
.wrapper_697b09 {
  background: rgba(243, 10, 80, 0.9);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content_697b09 {
  max-width: 600px;
  padding: 1rem;
}

.club_697b09 {
  background-color: rgba(243, 10, 80, 0.9);
}

.business_697b09 {
  background-color: rgba(20, 31, 82, 0.9);
}

.wedding_697b09 {
  background-color: rgba(234, 105, 104, 0.9);
}

.button_697b09 {
  font-size: 1rem;
  font-weight: 700;
  padding: 0.7rem 1.2rem;
  border: none;
  opacity: 1;
  color: #fff;
  border-radius: 50px;
  max-width: 200px;
  cursor: pointer;
  background-color: white;
  color: black;
  display: block;
  margin: 2rem 0;
}

.button_697b09:focus {
  outline: none;
}

@media all and (max-width: 800px) {
  .button_697b09 {
    max-width: 500px;
    margin-bottom: 2rem;
  }
}
.header_1de49e {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header_1de49e img {
  margin: 1rem;
}
.container_0e8a83 {
  display: flex;
  --stickerWidth: 300px;
  --stickerHeight: calc(var(--stickerWidth) * 1.4387096774);
}
.container_0e8a83 video,
.container_0e8a83 .camWrapper_0e8a83,
.container_0e8a83 .camWrapper_0e8a83 * {
  pointer-events: none;
}
@media all and (max-width: 800px) {
  .container_0e8a83 {
    flex-direction: column-reverse;
    align-items: center;
  }
}
.stickerForm_0e8a83 {
  padding: 2rem;
  flex: 1;
}
.stickerForm_0e8a83 h3 {
  font-size: 2rem;
  font-weight: 700;
}
.stickerForm_0e8a83 p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}
.stickerForm_0e8a83 form input {
  display: block;
}
@media all and (max-width: 800px) {
  .stickerForm_0e8a83 {
    padding: 1rem;
  }
}
.videoContainer_0e8a83 {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}
.camWrapper_0e8a83 {
  box-shadow: 0px 5px 50px #0000004f;
  margin: 2rem 1rem 2rem 0;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
@media all and (max-width: 800px) {
  .camWrapper_0e8a83 {
    margin: 0 calc((100vw - var(--stickerWidth)) / 2);
  }
  .camWrapper_0e8a83 + button {
    display: block;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
    padding: 1rem;
    float: right;
    margin-top: 1rem;
    position: relative;
    z-index: 1;
  }
}
.overlay_0e8a83 {
  position: absolute;
  top: 0;
}
.snapshotWrapper_0e8a83 {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 1;
  background: rgba(255, 255, 255, 0.98);
  padding: 1rem;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
@media all and (max-width: 800px) {
  .snapshotWrapper_0e8a83 {
    background: rgba(255, 255, 255, 0.98);
    display: block;
  }
}
.snapshotContent_0e8a83 {
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.snapshotContent_0e8a83 input {
  margin: 1rem 0;
}
.snapshotContent_0e8a83 img {
  width: 500px;
  max-width: 100%;
  box-shadow: 0rem 0rem 2rem rgba(0, 0, 0, 0.2);
  margin: 2rem;
}
@media all and (max-width: 800px) {
  .snapshotContent_0e8a83 {
    max-height: unset;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .snapshotContent_0e8a83 img {
    max-height: 70vh;
    margin: 4rem 0.5rem 1rem;
  }
}
.imageWrappper_0e8a83 {
  position: relative;
}
.imageWrappper_0e8a83 > div {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}
@media all and (max-width: 800px) {
  .imageWrappper_0e8a83 {
    max-width: 100vw;
  }
  .imageWrappper_0e8a83 > div {
    max-width: 100%;
    top: 4rem;
  }
}
.input_e28588 {
  background-color: rgba(0, 0, 0, 0.04);
  border: none;
  border-radius: 8px;
  padding: 0.7rem 1rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  transition: 0.4s ease-in;
  transition-property: box-shadow;
  min-width: 300px;
  font-family: "Rubik", sans-serif;
}
.input_e28588:focus {
  outline: none;
}
.input_e28588:focus {
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.03) inset;
  transition: 0.1s ease-out;
  transition-property: box-shadow;
}
@media all and (max-width: 800px) {
  .input_e28588 {
    min-width: unset;
    width: 100%;
    max-width: 500px;
  }
}
.input_e28588::placeholder {
  text-transform: uppercase;
  font-size: 0.9rem;
}
.button_e28588, .buttonClean_e28588, .buttonbosselem_e28588, .buttonesportmanager_e28588, .buttonfirefighter_e28588, .buttonclub_e28588, .buttonbusiness_e28588, .buttonwedding_e28588, .buttonLink_e28588 {
  font-family: "Rubik";
  font-size: 0.8rem;
  padding: 0.7rem 1.2rem;
  border: none;
  opacity: 1;
  color: #fff;
  border-radius: 10px;
  max-width: 350px;
  cursor: pointer;
  background-color: #f30a50;
  text-transform: uppercase;
  text-align: center;
  min-width: 300px;
}
.button_e28588:focus, .buttonClean_e28588:focus, .buttonbosselem_e28588:focus, .buttonesportmanager_e28588:focus, .buttonfirefighter_e28588:focus, .buttonclub_e28588:focus, .buttonbusiness_e28588:focus, .buttonwedding_e28588:focus, .buttonLink_e28588:focus {
  outline: none;
}
.button_e28588:disabled, .buttonClean_e28588:disabled, .buttonbosselem_e28588:disabled, .buttonesportmanager_e28588:disabled, .buttonfirefighter_e28588:disabled, .buttonclub_e28588:disabled, .buttonbusiness_e28588:disabled, .buttonwedding_e28588:disabled, .buttonLink_e28588:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
@media all and (max-width: 800px) {
  .button_e28588, .buttonClean_e28588, .buttonbosselem_e28588, .buttonesportmanager_e28588, .buttonfirefighter_e28588, .buttonclub_e28588, .buttonbusiness_e28588, .buttonwedding_e28588, .buttonLink_e28588 {
    margin-bottom: 2rem;
    display: block;
    width: 100%;
  }
}
.buttonLink_e28588 {
  color: black;
  background: transparent;
  text-transform: none;
  margin-bottom: 0.5rem;
}
.buttonwedding_e28588 {
  background-color: #ea6968;
}
.buttonbusiness_e28588 {
  background-color: #141f52;
}
.buttonclub_e28588 {
  background-color: #f30a50;
}
.buttonfirefighter_e28588 {
  background-color: black;
}
.moreLink_e28588, .moreLinkbosselem_e28588, .moreLinkfirefighter_e28588, .moreLinkclub_e28588, .moreLinkbusiness_e28588, .moreLinkwedding_e28588 {
  text-decoration: underline;
  padding: 0 4px;
  display: inline;
  font-weight: bold;
}
.moreLinkwedding_e28588 {
  color: #ea6968;
}
.moreLinkbusiness_e28588 {
  color: #141f52;
}
.moreLinkclub_e28588 {
  color: #f30a50;
}
.moreLinkfirefighter_e28588 {
  color: #f30a50;
}
.moreLinkbosselem_e28588 {
  color: #f30a50;
}
.buttonClean_e28588 {
  background-color: white;
  color: #333;
  margin-top: 1rem;
  display: inline-block;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
  text-align: center;
}
.checkbox_e28588 {
  font-size: 0.7rem;
  line-height: 1.5;
  display: inline-block;
  margin-bottom: 0.2rem;
  max-width: 350px;
  margin-bottom: 0.5rem;
}
.checkbox_e28588 + .checkbox_e28588 {
  margin-bottom: 1.5rem;
}
.checkbox_e28588 a {
  border-bottom: 1px solid;
}
.checkbox_e28588 input[type=checkbox] {
  margin: 0.2rem 0.5rem 0.2rem 0;
}
.button_0c4f27 {
  font-size: 16px;
  font-family: "Rubik";
  cursor: pointer;
  margin-right: 2rem;
  padding: 0.5rem 1.5rem;
  border-style: solid;
  border-width: 1px;
  border-color: #161f4e;
  border-radius: 4px;
  background-color: transparent;
  transition: border 200ms ease;
  text-align: center;
}
@media all and (max-width: 800px) {
  .button_0c4f27 {
    margin: 0 1rem;
  }
}
.button_0c4f27:hover {
  border-color: #dc3553;
  color: #dc3553;
}
.button__active_0c4f27, .button__active_0c4f27:hover {
  border-color: transparent;
  background-color: #161f4e;
  color: #fff;
}
.wrapper_0c4f27 {
  margin: 40px 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
}
@media all and (max-width: 800px) {
  .wrapper_0c4f27 {
    grid-template-columns: 1fr 1fr;
  }
}
@font-face {
  font-family: "Rubik";
  src: url("Rubik-Regular.94484dda.woff2") format("woff2"), url("Rubik-Regular.13284e9a.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Rubik";
  src: url("Rubik-SemiBold.04031404.woff2") format("woff2"), url("Rubik-SemiBold.798a38dd.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Rubik";
  src: url("Rubik-Bold.eeb23a24.woff2") format("woff2"), url("Rubik-Bold.957685a9.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Rubik", sans-serif;
}
a {
  color: inherit;
  text-decoration: none;
}
* {
  box-sizing: border-box;
}
#sticker-cam-root {
  overflow: scroll;
  height: calc(100% - 40px);
}
main {
  width: 100%;
}
/*# sourceMappingURL=index.c75bdaba.css.map */

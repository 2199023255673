.input {
  background-color: rgba(0, 0, 0, 0.04);
  border: none;
  border-radius: 8px;
  padding: 0.7rem 1rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  transition: 0.4s ease-in;
  transition-property: box-shadow;
  min-width: 300px;
  font-family: "Rubik", sans-serif;
}
.input:focus {
  outline: none;
}
.input:focus {
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.03) inset;
  transition: 0.1s ease-out;
  transition-property: box-shadow;
}
@media all and (max-width: 800px) {
  .input {
    min-width: unset;
    width: 100%;
    max-width: 500px;
  }
}

.input::placeholder {
  text-transform: uppercase;
  font-size: 0.9rem;
}

.button, .buttonClean, .buttonbosselem, .buttonesportmanager, .buttonfirefighter, .buttonclub, .buttonbusiness, .buttonwedding, .buttonLink {
  font-family: "Rubik";
  font-size: 0.8rem;
  padding: 0.7rem 1.2rem;
  border: none;
  opacity: 1;
  color: #fff;
  border-radius: 10px;
  max-width: 350px;
  cursor: pointer;
  background-color: #f30a50;
  text-transform: uppercase;
  text-align: center;
  min-width: 300px;
}
.button:focus, .buttonClean:focus, .buttonbosselem:focus, .buttonesportmanager:focus, .buttonfirefighter:focus, .buttonclub:focus, .buttonbusiness:focus, .buttonwedding:focus, .buttonLink:focus {
  outline: none;
}
.button:disabled, .buttonClean:disabled, .buttonbosselem:disabled, .buttonesportmanager:disabled, .buttonfirefighter:disabled, .buttonclub:disabled, .buttonbusiness:disabled, .buttonwedding:disabled, .buttonLink:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
@media all and (max-width: 800px) {
  .button, .buttonClean, .buttonbosselem, .buttonesportmanager, .buttonfirefighter, .buttonclub, .buttonbusiness, .buttonwedding, .buttonLink {
    margin-bottom: 2rem;
    display: block;
    width: 100%;
  }
}

.buttonLink {
  color: black;
  background: transparent;
  text-transform: none;
  margin-bottom: 0.5rem;
}

.buttonwedding {
  background-color: #ea6968;
}

.buttonbusiness {
  background-color: #141f52;
}

.buttonclub {
  background-color: #f30a50;
}

.buttonfirefighter {
  background-color: black;
}

.moreLink, .moreLinkbosselem, .moreLinkfirefighter, .moreLinkclub, .moreLinkbusiness, .moreLinkwedding {
  text-decoration: underline;
  padding: 0 4px;
  display: inline;
  font-weight: bold;
}

.moreLinkwedding {
  color: #ea6968;
}

.moreLinkbusiness {
  color: #141f52;
}

.moreLinkclub {
  color: #f30a50;
}

.moreLinkfirefighter {
  color: #f30a50;
}

.moreLinkbosselem {
  color: #f30a50;
}

.buttonClean {
  background-color: white;
  color: #333;
  margin-top: 1rem;
  display: inline-block;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
  text-align: center;
}

.checkbox {
  font-size: 0.7rem;
  line-height: 1.5;
  display: inline-block;
  margin-bottom: 0.2rem;
  max-width: 350px;
  margin-bottom: 0.5rem;
}
.checkbox + .checkbox {
  margin-bottom: 1.5rem;
}
.checkbox a {
  border-bottom: 1px solid;
}

.checkbox input[type=checkbox] {
  margin: 0.2rem 0.5rem 0.2rem 0;
}
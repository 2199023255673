.appWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 3rem 3rem;
}
@media all and (max-width: 800px) {
  .appWrapper {
    margin: 0 0 2rem;
    max-width: 100vw;
    overflow: hidden;
  }
}
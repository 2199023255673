.wrapper {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 2;
}
@media all and (max-width: 800px) {
  .wrapper {
    margin-left: 1rem;
  }
}

.buttonwrapper {
  position: relative;
}

.item {
  background-color: #f0bd26;
  color: white;
  font-weight: 400;
  padding: 1rem;
  margin: 2rem 0 0;
  border: 2px solid #d4a20f;
  border-radius: 4px;
}
@media all and (max-width: 800px) {
  .item {
    margin: 2rem 1rem 0;
  }
}
.wrapper {
  background: rgba(243, 10, 80, 0.9);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  max-width: 600px;
  padding: 1rem;
}

.club {
  background-color: rgba(243, 10, 80, 0.9);
}

.business {
  background-color: rgba(20, 31, 82, 0.9);
}

.wedding {
  background-color: rgba(234, 105, 104, 0.9);
}

.button {
  font-size: 1rem;
  font-weight: 700;
  padding: 0.7rem 1.2rem;
  border: none;
  opacity: 1;
  color: #fff;
  border-radius: 50px;
  max-width: 200px;
  cursor: pointer;
  background-color: white;
  color: black;
  display: block;
  margin: 2rem 0;
}
.button:focus {
  outline: none;
}
@media all and (max-width: 800px) {
  .button {
    max-width: 500px;
    margin-bottom: 2rem;
  }
}